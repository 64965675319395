.container .staff-chart-container{
    width: 100%;
    
    height: 250px;

}
.icon-other-staff-container{
    color:rgb(66, 124, 45);
    width: 30px;
    height: 30px;
    position: absolute;
    top: 207%;
    left: 14.5%;
    text-align: center;
    z-index: 1;
}
.other-staff-details-container{
    position: absolute;
    /* top: 47%; */
    top: -77px;
    left: -140.5%;
    z-index: 2;
    width: 500px;
    height: 350px;
    /* width: 250px; */
    box-shadow:0 0 20px 0 rgba(0,0,0,0.3607843137254902);

}
.other-staff-chart-container{
    width: 490px;
        /* height: 80%; */
        height: 340px;


}
.z-index-plus-one{
    z-index: 555;
}
.z-index-minus-one{
    z-index: -1;
}

/* #centerHeadline{
    background: fuchsia;
    font-size: 999px;
} */