.stats-bottom-element-container{
display: flex;
justify-content: flex-start;
}


.stats-bottom-element-container .col{
    margin: 0px 7px;
}
.stats-bottom-element-container {
    font-weight: 400 ;
font-size: 0.82rem;
}
.stats-bottom-element-container .amount{
    font-weight: 700;
}