
    .container .epidemic-chart-container, .container .age-gender-chart-container{
        /* background: pink; */
        width: 100%;
        height: 250px;
    }

@media(min-width:1000px){
    .container .epidemic-chart-container{
            /* background: pink; */
            width: 100%;
            height: 250px;
        }
    }
    

@media(min-width:1000px){
.container .epidemic-chart-container{
        /* background: pink; */
        width: 100%;
        height: 250px;
    }
}
