@import url(//fonts.googleapis.com/earlyaccess/opensanshebrew.css);
/* @font-face { font-family: OpenSansHebrew; src: url('../fonts/OpenSansHebrew-Regular.ttf'); }  */

body {
  margin: 0;
  font-family: 'Open Sans Hebrew';
  /* -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hamburger-butt{
    /* font-size: 30px; */
    color: #374f60;

   
 }
 .bar {
    width: 25px;
    height: 2px;
    background-color: #374f60; 
    margin: 5px 4px; 
  }
.button-default-view{
    color: white;
    background: #39475d;
    padding: 1px 6px;
    border-radius: 17px;
    height: 25px;
}
html {
         direction: rtl;
}
.button{
    cursor: pointer;
}
.display-none{
display: none;
}
.display-flex{
    display: flex;
 
}
.container{
    position: relative;
    background: var(--background-default-components);
    border-radius: 15px;
    padding:  7px;
    /* border: green solid 10px; */
}
.middle-elenet .text{
    background: #e6f1f4;
    border-radius: 15px;
    font-size: .7rem;
    font-weight: 400;
}
.sub-title{
    font-size: .9rem;
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 700 ;
}
*{
    
}
.none-visible{
    visibility: hidden;
}
.legend-container span .circle{
    display: inline;
    min-width: 30px;
height: 30px;
border-radius: 100%;

}
.legend-container span .circle.tests{
    background: teal;

}
.legend-container span .circle.verified{
    background: lightskyblue;

}
 
.selector-button{

}
.chart-container{
   background: none; 
}
/* mobile & common*/
:root{
    --header-heighet:40px;
    --header-heighet-small-screen-extra:40px; 
    --header-padding-top:16px;
 }

.app-NonGrid-container{
/* border: blueviolet  solid 8px; */
min-height: 1000px;
}
.app-container{
    /* border: green solid 7px; */

    /* background: yellow; */
    background: #edf1f7;

    position: relative;
    display: grid;
    grid-template-columns: 2.5% 95% 2.5%; 
    grid-template-rows: [row-1-start] 160px
    [row-1-end  row-2-start]auto  [row-2-end  row-3-start] auto
    [row-3-end  row-4-start] auto
    [row-4-end  row-5-start] auto
    [row-5-end  row-6-start] auto;
 
grid-template-areas: 
 ". emptyHeaderHolder ."
 ". stats-squares-row ."
". weekly-expanded-indexes-row ."
". fourth-line-row ."
". fith-line-row ."
". sixth-line-row ."

/*
   "courses-icon"
"info-links-small-screen"
"fith-line"
"sixth-line" */

;
}
@media  (min-width: 300px) and  (max-width:519px) {
    .app-container{
        grid-template-columns: 0 100% 0; 
        /* background: brown; */
    } 
  }
  
.emptyHeaderHolder {
    grid-area:  emptyHeaderHolder  ;
    /* height:calc( var( --header-heighet)  +var(--header-heighet-small-screen-extra) ) ; */
    /* background: yellow;  */
}   
.stats-squares-container{
    grid-area: stats-squares-row;
    /* background: darkcyan; */
}

.weekly-expanded-indexes-container{
    grid-area: weekly-expanded-indexes-row;

}
.fourth-line{
 grid-area: fourth-line-row;
display: flex;
flex-direction: column;
margin: 15px 0px;
 }

 .fith-line{
    grid-area: fith-line-row;
   display: flex;
   flex-direction: column;
   margin: 15px 0px;
    }
    .sixth-line{
        grid-area: sixth-line-row;
       display: flex;
       flex-direction: column;
       margin: 15px 0px;
        }
    
@media(min-width:1000px){

    :root{
        --header-heighet:75px;
     }
    
    .app-container{
        position: absolute;
        left: 0;

        width: 100%;
        transition: width linear 0.5s;
        /* width: 93%; */
        display: grid;
        /* grid-template-columns: minmax(0 , 1fr) [col-middle]  1030px  minmax(0 ,1fr) ; */

        grid-template-rows: [row-1-start] calc(40px + 16px) [row-1-end row-2-start] auto [row-2-end row-3-start]   auto 
        [row-3-end row-4-start]auto  [row-4-end  row-5-start] auto
        [row-5-end  row-6-start] auto;

        grid-template-rows: [row-1-start] calc(var( --header-heighet) + var(--header-padding-top)) [row-1-end row-2-start] auto [row-2-end row-3-start]   auto 
        [row-3-end row-4-start]auto  [row-4-end  row-5-start] auto
        [row-5-end  row-6-start] auto;
    grid-template-areas: 
 
    "emptyHeaderHolder emptyHeaderHolder emptyHeaderHolder"  
    ". stats-squares-row ."
    ". weekly-expanded-indexes-row ."

    ". fourth-line-row ."
    ". fith-line-row ."
    ". sixth-line-row ."

     ;
    } 
    .app-container.side-bar-open{
        width: 92.1%;
    }
    .emptyHeaderHolder{
        /* grid-area: emptyHeaderHolder; */
height: 40px;
height: var(--header-heighet);
/* border: red solid 5px; */
    } 
    .stats-squares-container{
        grid-area: stats-squares-row;
    }
 .weekly-expanded-indexes-container{
        grid-area: weekly-expanded-indexes-row;
    
    } 

    .fourth-line{
        flex-direction: row;
    }
    .fith-line{
        flex-direction: row;

        }
        .sixth-line{
            flex-direction: row;
    
            }
    
}

.logo-container{
    height:calc(var(--header-heighet) ) ;
    /* margin-bottom: 1rem;
 
    margin-left: 4rem; */
}
.logo-container img{
    height:calc(var(--header-heighet) ) ;
 
}
@media(min-width:1000px){
    .logo-container{
        height:calc(var(--header-heighet)*0.5) ;
        /* margin-bottom: 1rem;
     
        margin-left: 4rem; */
    }
    .logo-container img{
        height:calc(var(--header-heighet)*0.5) ;
     
    }
}
.side-bar-start{
position: fixed;
top: 0px;
right: 0px;
height: 100vh;
width: 0%;
transition: width linear 0.5s;

    flex-direction : column ;
  justify-content: space-around;
 
 margin-bottom: 4rem ;
 margin-top: 1.6rem ;
 background: #374f60;
 overflow: hidden;
}
@media  (min-width: 300px) and  (max-width:519px) {
    .side-bar-start{
        right: -50px;
    } 
  }

.side-bar-end{
    /* display : flex ; */
    width: 18%;

 }
 .side-bar-buttons-container{
     height: 43%;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     align-items: center;
     color: #bebebe;

 }
.side-bar-butt{
 width: 100%;
 text-align: center;
 font-size: 12px;
} 

.side-bar-butt.active{
    color: white;
border-right: #19d6e5 solid 7px;
}
.side-bar-butt.active svg{
    color:  #19d6e5;

}
.side-bar-butt.icon{
display: flex;
flex-direction: column;
/* justify-content: center; */
align-items: center;

}
.side-bar-butt.icon svg{
    margin-bottom: 8px;
}

@media (min-width: 1000px){
    .side-bar-end{
        width: 7% ;  
     }

}
 :root{
     --background-default-components:#ffffff;

 }
.stats-squares-container {
    display: flex;
    flex-direction: column;
     align-content: flex-start;
     margin-top: 15px;
    
 }
 .stats-squares-container .row{
     /* border: olive solid 5px; */
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-content: flex-start;
    width: 100%;
    /* border: olivedrab 4px solid; */

 }
 .stats-square-container{
     width: 50%;
 }
 .stats-squares-container .big-screen{
     display: none;
 }
   .stats-squares-container .medium-screen{
    display: none;
} 
.stats-square-container{
    height: 11.5rem;
     
margin: 4px;
padding: 7px;
     background: #ffffff;
     background: var(--background-default-components);
     border-radius: 7px;
 }
@media(min-width:1000px){
    .stats-squares-container .medium-screen{
        display: block;
    } 
    .stats-squares-container .small-screen{
        display: none;
    }
    .stats-square-container{
        width: 33%;
    }
    
}


@media(min-width:1335px){

    .stats-squares-container .medium-screen{
        display:none;
    } 
    .stats-squares-container .big-screen{
        display:flex;
    } 
    .stats-square-container{
        width: 33%;
    }
    
 


    /* .stats-square-container{
        height: 11.5rem;
         width: calc(100%/6);  
    margin: 4px;
    padding: 7px;
         background: wheat;
        max-width: 50%;
    } */
}
.stats-square-container{
display: flex;
flex-direction: column;

}
.stats-square-container .title{
    font-size: .9rem;
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 700 ;
}
.stats-square-container .sum{
    line-height: 1.1;
    font-size: 1.4rem;
    font-weight: 700 ;

}
.stats-square-container .difference{
    /* direction: rtl;     */
/* writing-mode: sideways-rl; */
/* writing-mode: sideways-lr; */
font-size: .8em;
    }
.stats-square-container .difference .amount{
    font-weight: 700 ;

}

.stats-square-container .amount-period{
    font-weight: normal;
    font-weight: initial ;

}
.stats-square-container .bottom-element {
    margin-top:80px ;
    margin-top: auto;
}




.stats-bottom-element-container{
display: flex;
justify-content: flex-start;
}


.stats-bottom-element-container .col{
    margin: 0px 7px;
}
.stats-bottom-element-container {
    font-weight: 400 ;
font-size: 0.82rem;
}
.stats-bottom-element-container .amount{
    font-weight: 700;
}
.daily-trend-container{
    position: relative;
}
.daily-trend-container .icon-container{
    border-radius: 50%;
    border: 1px solid rgba(151,151,151,.22);
    padding: 4px;
    margin-left: 5px;
    background: var(--background-default-components);
    /* height: 28px;
    width: 28px; */
}
.daily-trend-container .icon-container svg{
    height: 18px;
    width: 28px;

}


.daily-trend-Line-Chart-container{
background: var(--background-default-components);
border-radius: 15px;
position: absolute;
width: 65vw;
height: 55vh;
z-index:1;
/* background: lawngreen; */
box-shadow:0 0 20px 0 rgba(0,0,0,0.3607843137254902);
}

@media  (min-width: 300px) and  (max-width:519px) {
    .daily-trend-Line-Chart-container{
        position: fixed;
        left: 30%;
        top: 35%;
        width: 90vw;

        } 
  }


.display-center-viewport{
    position: fixed;
    left: 40%;
    top: 35%;
}
.display-popup-top{
    top: 40px;
     background: red;

}
.display-popup-down{
    bottom:  50px;
     background: blue;
}
.display-popup-right{
     left:10px;
    /* background: blue; */

}
.display-popup-left{
     left:10px;
    /* background: blue; */

}


.container .daily-trend-chart{
    width: 400px;
    height: 300px;
}
@media(min-width:800px){

    .container .daily-trend-chart{
        width: 450px;
        height: 250px;
    }

}


@media(min-width:1000px){
 
    .daily-trend-Line-Chart-container{
        width: 45vw;
        height: 45vh;
        
    }
    .container .daily-trend-chart{
        width: 600px;
        height: 250px;
    }
}
@media(min-width:1335px){

    .daily-trend-Line-Chart-container{
        width: 25vw;
        height: 35vh;
        
    }
    .container .daily-trend-chart{
        width: 700px;
        height: 200px;
    }

}
.red-drop{
    background: red;
    margin: 10px 0px 85px 0px;
    /* margin: 15px 0px; */
    width: 300px;;
    height: 300px;
    z-index: 3;
}
.graphs-container, .sicks-distribution-container-play{
    position: absolute;
    top: 500px;
    /* left: 30px; */
        left:400px;

    width: 500px;
    height: 200px;
    background: hotpink;
    z-index:3;
    margin: 0px 0px;

}
.sicks-distribution-container-play2{
    position: absolute;
    top: 800px;
    left: 400px;
    width: 800px;
    height: 500px;
    background: honeydew;
    z-index:0;
}
.sicks-distribution-container-play3{
    position: absolute;
    top: 1400px;
    left: 400px;
    width: 800px;
    height: 500px;
    background: forestgreen;
    z-index:0;

}
.graphs-container2{
    position: absolute;
    top: 500px;
    left: 400px;
    width: 800px;
    height: 500px;
    background: hotpink;
    z-index: 54;
}
#exapampleEchart1{
    background: peru;
    position: absolute;
    top: 150px;
    left: 390px;
    z-index: 6;
    width: 40%;
}
#exapampleEchart2{
    background: pink;
    position: absolute;
    top: 1400px;
    left: 390px;
    z-index: 6;
    width: 40%;
}

.echartExp{ 
    border: green solid 17px;
    width: 200px;
    height: 300px;
}
.drop-content{
    border: blue solid 17px;

}
@media(min-width:700px){
    .echartExp{
        border: pink solid 17px;
 
        /* width: auto;
        height: auto; */

        width: 500px;
        height: 500px;
    } 

}
#plswork{
    background: fuchsia;
}

/* style={{width:'500px', height:'600px',}}  */
:root{
    --border-weekly:#d8d8d9 solid 3px;
}

.weekly-expanded-indexes-container{
    background: var(--background-default-components);
    margin: 10px 0px;
    border-radius: 5px;
}
.upper-weekly-container{
    display: flex;
    flex-direction: column;
/* justify-content: center; */
align-items: center;
border-bottom:#d8d8d9 solid 3px;
border-bottom:var(
    --border-weekly);

}
.upper-weekly-container .start{
    display: none;
     }
 
.weekly-expanded-indexes-container .flex-item.middle{
    display: flex;
    justify-content:center ;
    font-size: 13px;
    text-decoration: none;
    font-weight: 700;
    color: #24343f;
    text-align: center;

        }
    
.weekly-expanded-indexes-container .flex-item.ending{
display: flex;
justify-content:flex-end ;
    }
    
.upper-weekly-headline{
    /* align-self: center; */
    /* width: 33%; */

}
.container.destination-line {
    /* width: 33%; */
    /* align-self: flex-end; */
    display: flex;
    align-items: center;
    margin: 0 0 0 5px;
    padding: 0;
    font-size: 12px;
  
}
.line-container.destination-line {
    width: 30px;
    display: flex;
    justify-content: space-between;
}
.line-container.destination-line {
    width: 30px;
    display: flex;
    justify-content: space-between;
}
.line.destination-line {
    width: 10px;
    height: 0;
    opacity: .7;
    border: 1px solid #f0465e;
}
.square.destination-line {
    width: 15px;
    height: 15px;
    background-color: #f0465e;
    margin-left: 5px;
}


 
@media(min-width:1000px){
    .weekly-expanded-indexes-container .flex-item.middle{
       
        font-size: 1.2rem;
        white-space: nowrap;
               }
               .upper-weekly-container{
                   flex-direction: row;
               }
 .container .destination-line{
font-size: medium;
font-size: initial;
                }
 .upper-weekly-container .start{
display: block;
 }
 .weekly-expanded-indexes-container .flex-item{
    width: 33%;
    /* border: darkgreen 5px solid; */
    }
    
}

.bottom-weekly-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.bottom-weekly-container .middle{
    border-left:#d8d8d9 solid 3px;
    border-left:var(
        --border-weekly) ;
    border-right:#d8d8d9 solid 3px;
    border-right:var(
        --border-weekly) ;
        border-bottom:#d8d8d9 solid 3px;
        border-bottom:var(
            --border-weekly) ; 
            border-radius: 0; 
            border-radius: initial;
}

.weekly-chart-container{
    padding: 5px 7px;
    /* margin: 0px 5px; */
    box-sizing: border-box;
}
.weekly-echart-container{
    /* background: burlywood; */
    width: 400px;
    height: 360px;
}
@media(min-width:650px){
    .weekly-echart-container{
         width: 500px;
    }    
}
@media(min-width:750px){
    .weekly-echart-container{
         width: 600px;
    }    
}
@media(min-width:1000px){
    .bottom-weekly-container{
 flex-direction: row;
justify-content: space-between;
    
    }
    .bottom-weekly-container .middle{
        border-bottom:none ; 
           
    }
     .bottom-weekly-container > div{
        width: 33%;
     }
     .weekly-echart-container{
        width: 100%;
   }    


}
.epidemic-container{
    /* width: 60%; */
 }
.severe-container, .sicks-distribution-container{
    /* width: 40%; */
margin: 15px 0px;

}
.upper-container{
    position: relative;
}
.upper-container .options-button-container{
    position: absolute;
    top: 3px;
    left:7px ;  
}
.selector-button-container{
     background:whitesmoke ;
    border-radius: 15px;
    font-weight: bolder;
    display: inline;
    padding: 3px 6px 3px 0px;
    /* margin-right: 13px; */
}
.options-button-container{
    display: inline;
    /* position: relative; */
}
/* .selector-button-container:hover */
 .prevent-propagation:hover{
    background:gray ;

}
.selector-button-container-items{
    display: flex;
    justify-content: space-between;
}
/* .prevent-propagation{
    background:red ;

} */
.options-container{
    position: absolute;

    background: white;
    border-style: solid;
    border-color: gray;
    border-width: 1px;
    border-radius: 10px;
    z-index: 1;
    margin-top: 10px;
    width: 128px;
}
.options-container div:hover{
    background: gray;

}
.arrow-animation{
      transform: rotate(180deg);  
    /* background: rgb(3, 3, 3); */
}
/* .selector-button-container:active {
    background: cyan;
  }
  
.selector-button-container:focus{
    background:greenyellow ;

} */
.selector-button-container{
    display: inline-block;
    width:128px;
    font-size: 0.7925rem;
    /* background: cyan; */
    /* position:relative; */
}
.selector-button-icon{
    margin: 0px 10px;
    display: inline-block;
    /* position: absolute;
    left: 111; */
    /* font-size: 1.5rem; */
 
}
.arrow-icon{
    display: inline-block;

width: 15px;
    height: 15px;
}

@media(min-width:1000px){
    .epidemic-container{
        width: 65%;
        margin-left: 15px;
    }
    .severe-container, .sicks-distribution-container{
        width: 35%;
        margin: 0;
        margin: initial; 
    } 
    .sicks-distribution-container{
        width: 30%;
        margin-left: 15px;

    }
}

.table-container{
    overflow-y: scroll; 
}
.table-container{
    /* width:450px ; */
    font-size: 2vw;


}
.table-container::-webkit-scrollbar {
  width: 10px;
  /* height: 10px; */
}
.table-container::-webkit-scrollbar-thumb {
    background: gray; 
    border-radius: 5px;
  }
  

th {
    cursor: pointer;
      }
      
 .hospital-status-container{
    margin: 15px 0px;

      }
      .hospital-status-table{
          width: 100%;
      }
/* .hospital-status-table th,  .hospital-status-table td{
background: lawngreen;
display: ;
} */
.crowded-percent{
    background: lightblue;
    display: inline-block; 
    /*  */
    height:10px;
    border-radius: 10px 0px 0px 10px;
    margin: 0px 5px;
}
.over-crowded{
    background: darkslategrey;

}
@media(min-width:770px){
    .table-container{
        /* width:450px ; */
        font-size: 1.5vw;
    
    
    }
    
}
  table {
}


@media(min-width:1000px){

    .epidemic-spread-container{
        width: 75%;
        /* height: 0px; */
        /* height: 200px; */
        /* overflow-y: scroll; */

    }
    .hospital-status-container{
        width: 45%;
        margin: 15px 0px;
    }
    .table-container{
        overflow-y: scroll; 
        font-size: medium; 
        font-size: initial;

    }
    th, td {
        padding: 5px;
          }
          .hospital-status-container{
            margin:0px 15px;
        }
  
}

@media(min-width:1000px){
    .staff-status-container, .detection-Chart-container{
        width: 27.5%;
    }

}
#sick-detection-chart{
   height: 230px;
}

    .container .epidemic-chart-container, .container .age-gender-chart-container{
        /* background: pink; */
        width: 100%;
        height: 250px;
    }

@media(min-width:1000px){
    .container .epidemic-chart-container{
            /* background: pink; */
            width: 100%;
            height: 250px;
        }
    }
    

@media(min-width:1000px){
.container .epidemic-chart-container{
        /* background: pink; */
        width: 100%;
        height: 250px;
    }
}


    .container .severe-chart-container{
        /* background: pink; */
        width: 100%;
        height: 250px;
    } 
     /*
@media(min-width:1000px){
.container .severe-chart-container{
       background: pink; 
        width: 100%;
        height: 250px;
    }
} */

.container .staff-chart-container{
    width: 100%;
    
    height: 250px;

}
.icon-other-staff-container{
    color:rgb(66, 124, 45);
    width: 30px;
    height: 30px;
    position: absolute;
    top: 207%;
    left: 14.5%;
    text-align: center;
    z-index: 1;
}
.other-staff-details-container{
    position: absolute;
    /* top: 47%; */
    top: -77px;
    left: -140.5%;
    z-index: 2;
    width: 500px;
    height: 350px;
    /* width: 250px; */
    box-shadow:0 0 20px 0 rgba(0,0,0,0.3607843137254902);

}
.other-staff-chart-container{
    width: 490px;
        /* height: 80%; */
        height: 340px;


}
.z-index-plus-one{
    z-index: 555;
}
.z-index-minus-one{
    z-index: -1;
}

/* #centerHeadline{
    background: fuchsia;
    font-size: 999px;
} */
 .main-header-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
/* height:calc(var(--header-heighet)+var(--header-heighet-small-screen-extra)); */
/* development version- fine in small screens  */
/* unfortunately inner css func's prevents heroku deployment */
/* height:80px;  production  version- problem in small screen */


  background:white;
  position: fixed;
 top: 0px;
 left: 0px;
width: 100%;
padding-top:var(--header-padding-top);  
z-index: 1; 
}
@media  (min-width: 300px) and  (max-width:519px) {
    .main-header-container{
        left: 52px;
    } 
  }
  


.main-header-container .header-start{
    width:100%;
     display: flex;
    justify-content: start;
    margin: 1rem 0rem;
 }
 
.main-header-container a{ 
    text-decoration: none;
}

.header-ending{
    /* margin-bottom: 1rem; */
    width:100%;
 display: flex;
 justify-content: space-between;
align-items: center;
 /* border: brown solid 4px; */
    margin :1rem 0rem;
    white-space: nowrap;
    text-align: right;
height: var(--header-heighet-small-screen-extra);
    color: black;
    padding: 0px 10px;

}
.header-ending .title-container .title{
    font-size: 13px ; 
    white-space: nowrap;
    text-decoration: none;
    font-weight: 700;
    color: #24343f;
}
.header-ending .title-container .sub-title{

    font-size: 10px ;
    color: #24343f;
}
.main-header-container .sub-title{
    font-weight: normal;
    font-weight: initial;
}
.header-ending .button-default-view {
margin-left: 17px;
}

@media(min-width:1000px){
    .main-header-container{
        /* height: var(--header-heighet); see above comment about  --header-heighet*/
        height: 75px;

        flex-direction: row;
        /* justify-content: flex-start; */
    }
    .main-header-container .header-start{
        width:30%;
         display: flex;
        justify-content: start;
     }

    .header-ending{
        margin-left: 2.5rem;
        justify-content: space-between;
    width:70%;
    text-align: center;


    }
    .main-header-container a{
        padding: 16px;
    }
    
    
    .header-ending .title-container .title{
        font-size: 1.2rem;
    }
    .header-ending .title-container .sub-title{
        font-size: .9rem;
    }
 
} 
